import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { database } from '../config';

export const firebaseCollection = (path: any) => {
  const extractPath = (document: any) => {
    const pathSegments = document._key.path;

    return pathSegments.segments.slice(-1 * pathSegments.len).join('/');
  };

  const add = async (profile: any) => {
    try {
      const subColRef = collection(database, `${typeof path === 'function' ? path() : path}`);
      const document = await addDoc(subColRef, profile);

      return { id: document.id, path: extractPath(document), data: profile };
    } catch (error) {
      console.error(`add :${error.message}`);
      throw error;
    }
  };

  const remove = async (id: string) => {
    try {
      const subColRef = collection(database, `${typeof path === 'function' ? path() : path}`);
      const subDocRef = doc(subColRef, id);

      await deleteDoc(subDocRef);
    } catch (error) {
      console.error(`remove :${error.message}`);
      throw error;
    }
  };

  const update = async (id: string, data: any) => {
    try {
      const subColRef = collection(database, `${typeof path === 'function' ? path() : path}`);
      const subDocRef = doc(subColRef, id);

      await updateDoc(subDocRef, data);
    } catch (error) {
      console.error(`update :${error.message}`);
      throw error;
    }
  };

  const get = async (id: string) => {
    try {
      const subColRef = collection(database, `${typeof path === 'function' ? path() : path}`);
      const subDocRef = doc(subColRef, id);
      const docSnap = await getDoc(subDocRef);

      if (docSnap.exists()) {
        return { id: docSnap.id, data: docSnap.data(), path: extractPath(docSnap) };
      }
    } catch (error) {
      console.error(`get :${error.message}`);
      throw error;
    }
  };

  const list = async () => {
    try {
      const subColRef = collection(database, `${typeof path === 'function' ? path() : path}`);
      const docSnap = await getDocs(subColRef);
      const documents: Array<any> = [];

      docSnap.forEach((document: any) => {
        documents.push(document.data());
      });

      return documents;
    } catch (error) {
      console.error(`list :${error.message}`);
      throw error;
    }
  };

  return { add, remove, update, list, get };
};
