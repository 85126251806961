import { useState } from 'react';
import { FormikValues } from 'formik';
import { firebaseCollection } from '../modules/api/collection.storage';
import { serverTimestamp } from 'firebase/firestore';

export const useFirebaseDatabase = () => {
  const [resume] = useState<string | undefined>();
  const [uploading, setUploading] = useState(false);

  async function saveContact(values: FormikValues) {
    const COLLECTION = 'contact-us';

    try {
      setUploading(true);
      const collection = firebaseCollection(`${COLLECTION}`);
      const timestamp = serverTimestamp();

      await collection.add({ ...values, timestamp });
    } catch (error) {
      console.error(`saveContact :${error.message}`);
      throw error;
    } finally {
      setUploading(false);
    }
  }

  async function saveEmail(values: FormikValues) {
    const COLLECTION = 'subscription';

    try {
      setUploading(true);
      const collection = firebaseCollection(`${COLLECTION}`);
      const timestamp = serverTimestamp();

      await collection.add({ ...values, timestamp });
    } catch (error) {
      console.error(`saveEmail :${error.message}`);
      throw error;
    } finally {
      setUploading(false);
    }
  }

  async function setId(id: string) {
    const COLLECTION = 'user-id';

    try {
      setUploading(true);
      const collection = firebaseCollection(`${COLLECTION}`);

      await collection.add({ id });
    } catch (error) {
      console.error(`setId :${error.message}`);
      throw error;
    } finally {
      setUploading(false);
    }
  }

  async function setRating(id: string, rating: number[], userid: string[]) {
    const COLLECTION = 'article-rating';
    const timestamp = serverTimestamp();

    try {
      setUploading(true);
      const collection = firebaseCollection(`${COLLECTION}`);

      await collection.update(id, {
        rating: rating,
        vote: userid,
        time: timestamp,
      });
    } catch (error) {
      console.error(`setRating :${error.message}`);
      throw error;
    } finally {
      setUploading(false);
    }
  }

  async function getRating(id: string) {
    const COLLECTION = 'article-rating';

    try {
      const collection = firebaseCollection(`${COLLECTION}`);

      return await collection.get(id);
    } catch (error) {
      console.error(`getRating :${error.message}`);
      throw error;
    }
  }

  return {
    saveContact,
    saveEmail,
    setRating,
    setId,
    getRating,
    uploading,
    resume,
  };
};
